.navbar {
    background-color: gray;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.navbar .logo {
    align-items: center;
}

.navbar img {
    border-radius: 50%;
    margin: 20px;
}

.navbar ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.navbar a {
    text-decoration: none;
    margin-right: 20px;
    align-content: center;
}
